import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'


const StyledFooter = styled.footer`
 text-align: center;
`

const Footer = () => (
  <StyledFooter className="footer">
      <Link to="/">Home</Link>
      <Link to="/privacy">Privacy</Link>
      <Link to="/terms">Terms</Link>
  </StyledFooter>
)

export default Footer
