import React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header";
import Container from "../components/UI/Container";
import Section from "../components/UI/Section";
import Footer from "../components/Footer";

const StyledArticle = styled.article`
    color: #fff;
    padding: 10px 0;
    font-size: 18px;
`

const StyledH2 = styled.h2`
    color: #fff;
`

const TermsPage = () => (
  <Layout>
    <Container>
      <SEO title="Download Bag Chat" />
      <Header />
      <Section>
          <StyledH2>Bag Chat Terms of Service</StyledH2>
          <StyledArticle>
            <h3>1. Terms</h3>
            <p>By accessing the website at http://bag.chat, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
          </StyledArticle>
          <StyledArticle>
              <h3>2. Use License</h3>
              <ol type="a">
                  <li>Permission is granted to temporarily download one copy of the materials (information or software) on Bag Chat's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                      <ol type="i">
                          <li>modify or copy the materials;</li>
                          <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                          <li>attempt to decompile or reverse engineer any software contained on Bag Chat's website;</li>
                          <li>remove any copyright or other proprietary notations from the materials; or</li>
                          <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                      </ol>
                      
                  </li>
                  <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Bag Chat at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
              </ol>
          </StyledArticle>
          <StyledArticle>
              <h3>3. Disclaimer</h3>
              <ol type="a">
                  <li>The materials on Bag Chat's website are provided on an 'as is' basis. Bag Chat makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
                  <li>Further, Bag Chat does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
              </ol>
          </StyledArticle>
          <StyledArticle>
              <h3>4. Limitations</h3>
              <p>In no event shall Bag Chat or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Bag Chat's website, even if Bag Chat or a Bag Chat authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
          </StyledArticle>
          <StyledArticle>
              <h3>5. Accuracy of materials</h3>
              <p>The materials appearing on Bag Chat's website could include technical, typographical, or photographic errors. Bag Chat does not warrant that any of the materials on its website are accurate, complete or current. Bag Chat may make changes to the materials contained on its website at any time without notice. However Bag Chat does not make any commitment to update the materials.</p>
          </StyledArticle>
          <StyledArticle>
              <h3>6. Links</h3>
              <p>Bag Chat has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Bag Chat of the site. Use of any such linked website is at the user's own risk.</p>
          </StyledArticle>
          <StyledArticle>
              <h3>7. Modifications</h3>
              <p>Bag Chat may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
          </StyledArticle>
          <StyledArticle>
              <h3>8. Governing Law</h3>
              <p>These terms and conditions are governed by and construed in accordance with the laws of California and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
          </StyledArticle>
      </Section>
      <Footer/>
    </Container>
  </Layout>
)

export default TermsPage
